<template>
  <div id="app">
    <nav-bar />
    <aside-menu :menu="menu" />
    <router-view class="flex" />
    <footer-bar />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NavBar from '../components/NavBar.vue';
import AsideMenu from '../components/AsideMenu.vue';
import FooterBar from '../components/FooterBar.vue';

export default {
  name: 'app',
  components: {
    FooterBar,
    AsideMenu,
    NavBar,
  },
  data() {
    return {
      menu: [
        {
          label: 'General',
          menu: [
            // {
            //   to: '/',
            //   icon: 'desktop-mac',
            //   label: 'Dashboard',
            // },
            {
              to: '/search',
              icon: 'magnify',
              label: 'Search',
            },
            {
              to: '/management',
              icon: 'table',
              label: 'Management',
            },
            // {
            //   to: '/transformation',
            //   icon: 'cog-transfer',
            //   label: 'Transformation',
            // },
            {
              to: '/integrations',
              icon: 'sitemap',
              label: 'Integrations',
              onlyAdmin: true,
            },
            {
              to: '/users',
              icon: 'account-supervisor',
              label: 'All users',
              onlyAdmin: true,
            },
            {
              to: '/applications',
              icon: 'apps',
              label: 'Applications',
            },
          ],
        },
        {
          label: 'User',
          menu: [
            {
              to: '/profile',
              label: 'Profile',
              icon: 'account-circle',
            },
          ],
        },
        {
          label: 'Billing',
          onlyAdmin: true,
          menu: [
            {
              to: '/billing/contracts',
              label: 'Contracts',
              icon: 'file-document-edit',
            },
            // {
            //   to: '/billing/report',
            //   label: 'Report',
            //   icon: 'reorder-horizontal',
            // },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState(['userIsAdmin']),
  },
  created() {
    if (this.$store.state.profile.id !== this.$store.state.userId) {
      this.$http.get('/v1/profile').then((res) => {
        this.$store.commit('profile', res.data);
      });
    }
  },
  methods: {
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => this.$route.path.indexOf(path) === 0);
    },
  },
};
</script>
