import Model from './index';

export default {
  getAll(groupId, sortField, sortOrder, limit, offset) {
    return Model.get('/v1/devices', {
      params: {
        group_id: groupId,
        sort_field: sortField,
        sort_order: sortOrder,
        limit,
        offset,
      },
    });
  },
  getOne(id) {
    return Model.get(`/v1/device/${id}`);
  },
  add(obj) {
    return Model.post('/v1/devices', obj);
  },
  update(obj) {
    return Model.put(`/v1/device/${obj.id}`, obj);
  },
  delete(id) {
    return Model.delete(`/v1/device/${id}`);
  },
  transfer(obj) {
    return Model.post(`/v1/device/${obj.id}/transfer`, obj);
  },
};
