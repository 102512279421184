import Model from './index';

export default {
  getAll(groupId) {
    return Model.get('/v1/callbacks', { params: { group_id: groupId } });
  },
  getOne(id) {
    return Model.get(`/v1/callback/${id}`);
  },
  add(obj) {
    return Model.post('/v1/callbacks', obj);
  },
  update(obj) {
    return Model.put(`/v1/callback/${obj.id}`, obj);
  },
  delete(id) {
    return Model.delete(`/v1/callback/${id}`);
  },
};
