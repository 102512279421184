<template>
  <section class="section is-main-section">
    <Breadcrumb />
    <router-view />
  </section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue';

export default {
  name: 'Management',
  components: {
    Breadcrumb,
  },
};
</script>
