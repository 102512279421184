<template>
  <ul :class="{'menu-list':!isSubmenuList}">
    <template v-for="(item, index) in menu" >
    <aside-menu-item
      v-if="!item.onlyAdmin || userIsAdmin"
      @menu-click="menuClick"
      :item="item"
      :key="index"/>
    </template>
  </ul>
</template>

<script>
import { mapState } from 'vuex';
import AsideMenuItem from '@/components/AsideMenuItem.vue';

export default {
  name: 'AsideMenuList',
  components: {
    AsideMenuItem,
  },
  props: {
    isSubmenuList: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState([
      'userIsAdmin',
    ]),
  },
  methods: {
    menuClick(item) {
      this.$emit('menu-click', item);
    },
  },
};
</script>
