import Vue from 'vue';
import Router from 'vue-router';
import Base from './views/Base.vue';
import Management from './views/Management.vue';

Vue.use(Router);

export default new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
    },
    {
      path: '/',
      component: Base,
      redirect: {
        name: 'organizations',
      },
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
        },
        {
          path: 'management',
          component: Management,
          redirect: {
            name: 'organizations',
          },
          children: [
            {
              path: 'organizations',
              name: 'organizations',
              component: () => import(/* webpackChunkName: "organizations" */ './views/Organizations.vue'),
            },
            {
              path: 'organization-add',
              name: 'organization-add',
              component: () => import(/* webpackChunkName: "organization" */ './views/Organization.vue'),
            },
            {
              path: 'organization/:id',
              name: 'organization',
              component: () => import(/* webpackChunkName: "organization" */ './views/Organization.vue'),
            },
            {
              path: 'organization/:organizationId/groups',
              name: 'groups',
              component: () => import(/* webpackChunkName: "groups" */ './views/Groups.vue'),
            },
            {
              path: 'organization/:organizationId/group-add',
              name: 'group-add',
              component: () => import(/* webpackChunkName: "group" */ './views/Group.vue'),
            },
            {
              path: 'group/:id',
              name: 'group',
              component: () => import(/* webpackChunkName: "group" */ './views/Group.vue'),
            },
            {
              path: 'group/:groupId/devices',
              name: 'devices',
              component: () => import(/* webpackChunkName: "devices" */ './views/Devices.vue'),
            },
            {
              path: 'group/:groupId/device-add',
              name: 'device-add',
              component: () => import(/* webpackChunkName: "device" */ './views/Device.vue'),
            },
            {
              path: 'device/:id',
              name: 'device',
              component: () => import(/* webpackChunkName: "device" */ './views/Device.vue'),
            },
            {
              path: 'device/:id/transfer',
              name: 'device-transfer',
              component: () => import(/* webpackChunkName: "device-transfer" */ './views/DeviceTransfer.vue'),
            },
            {
              path: 'device/:deviceId/messages',
              name: 'messages',
              component: () => import(/* webpackChunkName: "messages" */ './views/Messages.vue'),
            },
            {
              path: 'device/:deviceId/smart-shelf',
              name: 'smart-shelf',
              component: () => import(/* webpackChunkName: "messages" */ './views/SmartShelf.vue'),
            },
            {
              path: 'message/:id',
              name: 'message',
              component: () => import(/* webpackChunkName: "message" */ './views/Message.vue'),
            },
            {
              path: 'group/:groupId/callbacks',
              name: 'callbacks',
              component: () => import(/* webpackChunkName: "callbacks" */ './views/Callbacks.vue'),
            },
            {
              path: 'group/:groupId/callback-add',
              name: 'callback-add',
              component: () => import(/* webpackChunkName: "callback" */ './views/Callback.vue'),
            },
            {
              path: 'callback/:id',
              name: 'callback',
              component: () => import(/* webpackChunkName: "callback" */ './views/Callback.vue'),
            },
          ],
        },
        {
          path: 'integrations',
          component: () => import(/* webpackChunkName: "integrations" */ './views/integrations/Base.vue'),
          redirect: {
            name: 'integrations-list',
          },
          children: [
            {
              path: 'list',
              name: 'integrations-list',
              component: () => import(/* webpackChunkName: "integrations" */ './views/integrations/List.vue'),
            },
            {
              path: 'add',
              name: 'integration-add',
              component: () => import(/* webpackChunkName: "integrations" */ './views/integrations/Add.vue'),
            },
            {
              path: ':id/webhook',
              name: 'integrations-webhook',
              component: () => import(/* webpackChunkName: "integrations" */ './views/integrations/Webhook.vue'),
            },
            {
              path: 'new/webhook',
              name: 'integrations-new-webhook',
              component: () => import(/* webpackChunkName: "integrations" */ './views/integrations/Webhook.vue'),
            },
          ],
        },
        {
          path: 'users',
          name: 'users',
          component: () => import(/* webpackChunkName: "users" */ './views/Users.vue'),
        },
        {
          path: 'user/add',
          name: 'user-add',
          component: () => import(/* webpackChunkName: "user" */ './views/User.vue'),
        },
        {
          path: 'user/:id',
          name: 'user',
          component: () => import(/* webpackChunkName: "user" */ './views/User.vue'),
        },
        {
          path: 'search',
          name: 'search',
          component: () => import(/* webpackChunkName: "search" */ './views/Search.vue'),
        },
        {
          path: 'profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "search" */ './views/Profile.vue'),
        },
        {
          path: 'applications',
          name: 'applications',
          component: () => import(/* webpackChunkName: "applications" */ './views/Applications.vue'),
        },
        {
          path: 'application/add',
          name: 'application-add',
          component: () => import(/* webpackChunkName: "applications" */ './views/Application.vue'),
        },
        {
          path: 'application/:id',
          name: 'application',
          component: () => import(/* webpackChunkName: "applications" */ './views/Application.vue'),
        },
        {
          path: 'billing',
          component: () => import(/* webpackChunkName: "billing" */ './views/billing/Base.vue'),
          redirect: {
            name: 'contracts',
          },
          children: [
            {
              path: 'contracts',
              name: 'contracts',
              component: () => import(/* webpackChunkName: "billing" */ './views/billing/Contracts.vue'),
            },
            {
              path: 'contract/add',
              name: 'contract-add',
              component: () => import(/* webpackChunkName: "billing" */ './views/billing/Contract.vue'),
            },
            {
              path: 'contract/:id',
              name: 'contract',
              component: () => import(/* webpackChunkName: "billing" */ './views/billing/Contract.vue'),
            },
            {
              path: 'contract/:id/report/:reportId',
              name: 'contract-report',
              component: () => import(/* webpackChunkName: "billing" */ './views/billing/Report.vue'),
            },
          ],
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});
