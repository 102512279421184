/* Styles */
import '@/scss/main.scss';
import '@mdi/font/css/materialdesignicons.css';
import 'bulma-extensions/dist/css/bulma-extensions.min.css';
import 'vue-toast-notification/dist/theme-default.css';
import 'vue-simple-markdown/dist/vue-simple-markdown.css';
import 'vue-json-pretty/lib/styles.css';

/* Core */
import Vue from 'vue';
import Buefy from 'buefy';
import VueMoment from 'vue-moment';
import VueToast from 'vue-toast-notification';
import VueAuthenticate from 'vue-authenticate';
import VueClipboard from 'vue-clipboard2';
import moment from 'moment';
import VueSimpleMarkdown from 'vue-simple-markdown';

/* Router & Store */
import router from './router';
import store from './store';

/* Vue. Main component */
import App from './App.vue';

/* Vue. Component in recursion */
import AsideMenuList from './components/AsideMenuList.vue';

// eslint-disable-next-line no-nested-ternary
const baseApiUrl = window.location.host === 'hardwario.cloud'
  ? 'https://api.hardwario.cloud' : 'https://devapi.hardwario.cloud';

// const baseApiUrl = 'http://localhost:4000';

Vue.config.productionTip = false;
Vue.prototype.baseApiUrl = baseApiUrl;

/* These components are used in recursion algorithm */
Vue.component('AsideMenuList', AsideMenuList);
Vue.use(VueMoment);
Vue.use(Buefy, {
  defaultDateFormatter(date) {
    // return date.toISOString().slice(0, 10);
    return date ? moment(date).format('YYYY-MM-DD') : null;
  },
});
Vue.use(VueToast, { position: 'top-right' });
Vue.use(VueClipboard);
Vue.use(VueAuthenticate, {
  tokenType: 'Bearer',
  loginUrl: '/v1/auth/login',
  providers: {
    google: {
      url: 'v1/auth/google/callback',
      clientId: '886732002208-l43ej0671h02831pr18odf724n8mpaup.apps.googleusercontent.com',
    },
  },
  bindRequestInterceptor() {
    this.$http.interceptors.request.use((inConfig) => {
      const config = inConfig;
      config.baseURL = baseApiUrl;

      if (this.isAuthenticated()) {
        config.headers.Authorization = [this.options.tokenType, this.getToken()].join(' ');
      } else {
        delete config.headers.Authorization;
      }
      return config;
    });
  },
});
Vue.use(VueSimpleMarkdown);

Vue.prototype.$http.interceptors.response.use((response) => response, (error) => {
  if (error && error.response && error.response.status === 401) {
    Vue.prototype.$auth.logout();
    router.push({ name: 'login' });
  }
  return Promise.reject(error);
});

Vue.filter('capitalize', (value) => {
  if (!value) return '';
  const tmp = value.toString();
  return tmp.charAt(0).toUpperCase() + tmp.slice(1);
});

router.beforeEach((to, from, next) => {
  const authenticated = Vue.prototype.$auth.isAuthenticated();
  if ((to.path !== '/login' && to.path !== 'login') && !authenticated) {
    if (to.path !== '/management/organizations') {
      localStorage.setItem('redirectPath', to.fullPath);
    }
    next({ path: '/login' });
  } else if ((to.path === '/login' || to.path === 'login') && authenticated) {
    next({ path: '/' });
  } else {
    next();
  }
});

/* Collapse mobile aside menu on route change */
router.afterEach(() => {
  store.commit('asideMobileStateToggle', false);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#main');
