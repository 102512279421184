<template>
  <aside
      v-show="isAsideVisible"
      class="aside is-placed-left is-expanded">
    <aside-tools :is-main-menu="true">
      <span slot="label">
        <b>HARDWARIO</b> Cloud
      </span>
    </aside-tools>
    <div class="menu is-menu-main">
      <template v-for="(item, index) in menu" >
        <p class="menu-label" v-if="!item.onlyAdmin || userIsAdmin" :key="index">{{ item.label }}</p>
        <aside-menu-list
          v-if="!item.onlyAdmin || userIsAdmin"
            :key="'list' + index"
            @menu-click="menuClick"
            :menu="item.menu"/>
      </template>
    </div>
  </aside>
</template>

<script>
import { mapState } from 'vuex';
import AsideTools from '@/components/AsideTools.vue';
import AsideMenuList from '@/components/AsideMenuList.vue';

export default {
  name: 'AsideMenu',
  components: { AsideTools, AsideMenuList },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState([
      'isAsideVisible',
      'userIsAdmin',
    ]),
  },
  methods: {
    menuClick() {
    },
  },
};
</script>
<style>

aside li a.router-link-active span.menu-item-label{
  color: white;
}
</style>
