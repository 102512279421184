import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import createLogger from 'vuex/dist/logger';

Vue.use(Vuex);

const storage = window.localStorage || localStorage;

export default new Vuex.Store({
  state: {
    userId: null,
    userIsAdmin: false,
    profile: JSON.parse(storage.getItem('profile')) || {},

    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false,

    cache: {},
  },
  mutations: {
    /* A fit-them-all commit */
    basic(state, payload) {
      state[payload.key] = payload.value;
    },

    token(state, token) {
      if (!token) return;
      if (token.type !== 'web' && token.type !== 'user') return;

      if (token.id) {
        state.userId = token.id;
      }

      if (token.is_admin === true) {
        state.userIsAdmin = true;
      } else {
        state.userIsAdmin = false;
      }
    },

    profile(state, profile) {
      state.profile = profile;
      storage.setItem('profile', JSON.stringify(profile));
    },

    /* Aside Mobile */
    asideMobileStateToggle(state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded';

      let isShow;

      if (payload !== null) {
        isShow = payload;
      } else {
        isShow = !state.isAsideMobileExpanded;
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName);
      } else {
        document.documentElement.classList.remove(htmlClassName);
      }

      state.isAsideMobileExpanded = isShow;
    },
  },
  actions: {
    fetch_cache: async ({ state }, args) => {
      const key = `${args.name}/${args.id}`;
      const item = state.cache[key];
      if (item !== undefined) return item;
      const { data } = await axios.get(`/v1/${args.name}/${args.id}`);
      state.cache[key] = data;
      return data;
    },
    fetch_group: ({ dispatch }, id) => dispatch('fetch_cache', { name: 'group', id }),
    fetch_organization: ({ dispatch }, id) => dispatch('fetch_cache', { name: 'organization', id }),
    fetch_integration: async ({ dispatch }, id) => dispatch('fetch_cache', { name: 'integration', id }),
  },
  plugins: [
    createLogger(),
  ],
});
