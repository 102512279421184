import Model from './index';

export default {
  getAll(deviceId, sortField, sortOrder, limit, offset, since, before) {
    return Model.get('/v1/messages', {
      params: {
        device_id: deviceId,
        sort_field: sortField,
        sort_order: sortOrder,
        limit,
        offset,
        since,
        before,
      },
    });
  },
  getOne(id) {
    return Model.get(`/v1/message/${id}`);
  },
  delete(id) {
    return Model.delete(`/v1/message/${id}`);
  },
  deleteAll(deviceId) {
    return Model.delete('/v1/messages', {
      params: {
        device_id: deviceId,
      },
    });
  },
};
