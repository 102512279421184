<template>
  <nav class="breadcrumb" aria-label="breadcrumbs" v-if="showBreadcrumb">
    <ul>
      <li><router-link :to="{ name: 'organizations' }">Organizations</router-link></li>
      <li v-if="organization">
        <router-link :to="{ name: 'organization', params: { id: organization.id } }">{{ organization.name }}</router-link>
      </li>
      <li v-if="organizationId">
        <router-link :to="{ name: 'groups', params: { organizationId }}">
          Groups
        </router-link>
      </li>
      <li v-if="group">
        <router-link :to="{ name: 'group', params: { id: group.id } }">{{ group.name }}</router-link>
      </li>

      <li v-if="groupId">
        <router-link v-if="!this.$route.name.startsWith('callback')"
                     :to="{ name: 'devices', params: { groupId }}">
          Devices
        </router-link>
        <router-link v-if="this.$route.name.startsWith('callback')"
                     :to="{ name: 'callbacks', params: { groupId }}">
          Callbacks
        </router-link>
      </li>
      <li class="is-active" v-if="deviceId">
        <a href="#" aria-current="page">{{ deviceLabel }}</a>
      </li>
      <li v-if="deviceId && this.$route.name.startsWith('message')">
        <router-link :to="{ name: 'messages', params: { deviceId }}">
          Messages
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import Organization from '../models/Organization';
import Group from '../models/Group';
import Device from '../models/Device';
import Message from '../models/Message';
import Callback from '../models/Callback';

export default {
  name: 'Breadcrumb',
  data() {
    return {
      organizationId: null,
      groupId: null,
      deviceId: null,

      organization: null,
      group: null,
      deviceLabel: null,
    };
  },
  mounted() {
    this.update();
  },
  computed: {
    showBreadcrumb() {
      return this.$route.name !== 'login';
    },
  },
  watch: {
    $route() {
      this.update();
    },
  },
  methods: {
    async getOrganization(organizationId) {
      const { data } = await Organization.getOne(organizationId);
      this.organization = data;
    },
    async getGroup(groupId) {
      const { data } = await Group.getOne(groupId);
      this.group = data;
      if (this.$route.name === 'groups') return;
      this.organizationId = data.organization_id;
      this.getOrganization(data.organization_id);
    },
    async getDevice(deviceId) {
      const { data } = await Device.getOne(deviceId);
      this.groupId = data.group_id;
      this.deviceLabel = data.label;
      this.getGroup(this.groupId);
    },
    async getMessage(messageId) {
      const { data } = await Message.getOne(messageId);
      this.deviceId = data.device_id;
      this.groupId = data.group_id;
      this.getGroup(this.groupId);
    },
    async getCallback(callbackId) {
      const { data } = await Callback.getOne(callbackId);
      this.groupId = data.group_id;
      this.getGroup(this.groupId);
    },
    async update() {
      if (this.$route.name === 'organization') {
        this.getOrganization(this.$route.params.id);
        this.organizationId = null;
        this.groupId = null;
        this.deviceId = null;
        this.group = null;
        this.deviceLabel = null;
      } else if (this.$route.params.organizationId) {
        this.organizationId = this.$route.params.organizationId;
        this.getOrganization(this.organizationId);
        this.groupId = null;
        this.deviceId = null;
      } else if (this.$route.name === 'group') {
        this.getGroup(this.$route.params.id);
        this.groupId = null;
        this.deviceId = null;
      } else if (this.$route.params.groupId) {
        this.groupId = this.$route.params.groupId;
        this.getGroup(this.groupId);
        this.deviceId = null;
      } else if (this.$route.name === 'device' || this.$route.name === 'device-transfer') {
        this.deviceId = this.$route.params.deviceId || this.$route.params.id;
        this.getDevice(this.deviceId);
      } else if (this.$route.params.deviceId) {
        this.deviceId = this.$route.params.deviceId;
        this.getDevice(this.deviceId);
      } else if (this.$route.name === 'message') {
        this.getMessage(this.$route.params.id);
      } else if (this.$route.name === 'callback') {
        this.getCallback(this.$route.params.id);
      } else {
        this.organizationId = null;
        this.groupId = null;
        this.deviceId = null;
        this.callbackId = null;
      }
    },
  },
};
</script>
<style >
</style>
