// import router from '../router';
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

// const baseURL = '/api';
// const token = localStorage.getItem('apiToken');

// const http = axios.create({
//   baseURL,
//   headers: {
//     Authorization: `Bearer ${token}`,
//   },
// });

Vue.prototype.$http.interceptors.response.use((response) => response, (error) => {
  try {
    const msg = error.response.data;
    Vue.$toast.error(msg);
  } catch (e) {
    Vue.$toast.error(error.toString());
  }
  return Promise.reject(error);
});

export default Vue.prototype.$http;
