import Model from './index';

export default {
  getAll(organizationId, sortField, sortOrder, limit, offset) {
    return Model.get('/v1/groups', {
      params: {
        organization_id: organizationId,
        sort_field: sortField,
        sort_order: sortOrder,
        limit,
        offset,
      },
    });
  },
  getOne(id) {
    return Model.get(`/v1/group/${id}`);
  },
  add(obj) {
    return Model.post('/v1/groups', obj);
  },
  update(obj) {
    return Model.put(`/v1/group/${obj.id}`, obj);
  },
  delete(id) {
    return Model.delete(`/v1/group/${id}`);
  },
};
